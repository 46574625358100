import React, { ReactElement } from 'react'
import { FaTwitter } from 'react-icons/fa'
// import { FaRegClone } from 'react-icons/fa'

import { IoMdClose, IoMdCopy } from 'react-icons/io'

import { DeleteIcon, SettingsIcon } from '@chakra-ui/icons'
import {
  Flex,
  Divider,
  Text,
  useClipboard,
  useToast,
  VStack,
} from '@chakra-ui/react'
import db from '../../../../utils/firebase/firestore'
//import { AiOutlineCamera } from 'react-icons/ai'
import { useCurrentUser } from '../../../../context/user/context'
import { routes } from '../../../../utils/constants'
import UnfollowDashboardButton from '../UnfollowDashboardButton'
import shareToTwitter from '../../../../utils/general/shareToTwitter'
import { DashboardDraggableData } from '../../../layout/navigation/Sidebar/DashboardsList'
import DeleteDashboardButton from '../../dashboardSettings/DeleteDashboardButton'
import { useRouter } from 'next/router'
import firebase from '../../../../utils/firebase/firebase'
import { useDashboardSettingsModalContext } from '../../../../context/dashboard-settings-modal/context'

type EventHandler = () => void

export interface Props {
  dashboard: DashboardDraggableData
  onFollow: () => Promise<void>
  onClone: EventHandler
  isAuthor: boolean
  isFollowing: boolean
}

const DashboardMenu = ({ dashboard }: Props): ReactElement => {
  const sharableLink = routes.shareableDashboardLink(dashboard.id)
  const { user } = useCurrentUser()
  const isAuthor = user != undefined && user.uid === dashboard.author.id

  const router = useRouter()
  const stopPropagationHandler = (
    e: React.MouseEvent,
    handler: React.MouseEventHandler
  ) => {
    e.stopPropagation()
    handler(e)
  }

  const menuItemStyles = {
    _hover: { bg: 'gray.100', cursor: 'pointer' },
    w: '100%',
    align: 'flex-start',
    py: 1,
    px: 2,
  }

  const { onCopy } = useClipboard(sharableLink)
  const toast = useToast()

  const handleCopyShareLink = () => {
    onCopy()
    //setEverCopied(true) // persist copied state
    toast({
      title: 'Link copied!',
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  const { openSettings } = useDashboardSettingsModalContext()

  const handleOpenSettings = () => {
    openSettings(dashboard.id)
  }

  const handleShareToTwitter = () => shareToTwitter(dashboard, user)

  const handleDeleteDashboard = () => {
    if (dashboard != null) {
      const dashboardRef = db.doc(`dashboards/${dashboard.id}`)

      dashboardRef.delete().catch((error) => {
        console.error(error)
        return
      })

      if (router.query.id === dashboard.id) {
        router.push({
          pathname: routes.home,
          query: {},
        })
      }

      // TODO: delete user settings
    }
  }

  const handleUnfollow = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (user?.uid == undefined) {
        reject('userId is undefined')
        return
      }
      resolve(
        db.doc(`users/${user?.uid}`).update({
          dashboardIdsInOrder: firebase.firestore.FieldValue.arrayRemove(
            dashboard.id
          ),
          followedDashboards: firebase.firestore.FieldValue.arrayRemove(
            dashboard.id
          ),
        })
      )

      if (router.query.id === dashboard.id) {
        router.push({
          pathname: routes.home,
          query: {},
        })
      }
    })
  }

  return (
    <VStack
      w="100%"
      pt={2}
      pb={2}
      m={0}
      borderRadius="md"
      align={'flex-start'}
      fontSize="12"
      boxShadow="0 0 5px rgb(0 0 0 / 0.35)"
      spacing={0}
      key={dashboard.id}
    >
      {isAuthor && (
        <>
          <Flex
            onClick={(e) => {
              stopPropagationHandler(e, handleOpenSettings)
            }}
            {...menuItemStyles}
          >
            <Text mr={2} mt={'-4px'} fontSize={14}>
              <SettingsIcon />
            </Text>
            Settings
          </Flex>
          <DeleteDashboardButton
            dashboardTitle={dashboard.title}
            as={
              <Flex {...menuItemStyles}>
                <Text mr={2} mt={'-4px'} fontSize={14}>
                  <DeleteIcon />
                </Text>
                Delete dashboard
              </Flex>
            }
            shouldStopClickPropagation={true}
            onDelete={handleDeleteDashboard}
          />

          <Flex
            w="100%"
            justify="center"
            px="5px"
            py="4px"
            borderColor="gray.300"
          >
            <Divider />
          </Flex>
        </>
      )}
      {!isAuthor && (
        <Flex w="100%" _hover={{ cursor: 'pointer' }}>
          <UnfollowDashboardButton
            buttonComponent={
              <Flex {...menuItemStyles}>
                <Text mr={2} fontSize={14}>
                  <IoMdClose />
                </Text>{' '}
                Unfollow
              </Flex>
            }
            onUnfollow={handleUnfollow}
            preventDefaultClickAction={true}
            stopClickPropagation={true}
            dashboardTitle={dashboard.title}
          />
        </Flex>
      )}
      <Flex
        onClick={(e) => stopPropagationHandler(e, handleShareToTwitter)}
        {...menuItemStyles}
      >
        <Text mt="2px" mr={2} fontSize={12}>
          <FaTwitter />
        </Text>{' '}
        Share to Twitter
      </Flex>
      <Flex
        onClick={(e) => {
          stopPropagationHandler(e, handleCopyShareLink)
        }}
        {...menuItemStyles}
      >
        <Text mr={2} mt="1px" fontSize={14} as="span">
          <IoMdCopy />
        </Text>{' '}
        Copy link
      </Flex>
    </VStack>
  )
}

export default DashboardMenu
