/**
 * Component for a single link in the nav bar
 */
import React, { ReactNode, ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import NextLink from 'next/link'
import { bg, bgSelected, bgActive } from './utils'

interface Props {
  children: ReactNode
  href?: string
  onClick?: () => void
  isSelected?: boolean
  isDisabled?: boolean
}

const NavLink = ({
  children,
  href = '',
  onClick,
  isSelected = false,
  isDisabled = false,
}: Props): ReactElement => {
  const linkStyles = {
    px: 3,
    py: 3,
    fontSize: '18px',
    lineHeight: '10px',
    fontWeight: 'medium',
    rounded: 'md',
    bg: isSelected ? bgSelected : 'auto',
    _hover: {
      textDecoration: 'none',
      bg: isSelected ? 'auto' : bg,
      cursor: 'pointer',
    },
    _active: {
      bg: bgActive,
    },
  }

  if (href === '') {
    return (
      <Box {...linkStyles} onClick={isDisabled ? undefined : onClick}>
        {children}
      </Box>
    )
  }

  return (
    <NextLink href={href} passHref>
      <Box onClick={isDisabled ? undefined : onClick} {...linkStyles} as={'a'}>
        {children}
      </Box>
    </NextLink>
  )
}

export default NavLink
