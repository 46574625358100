import React, { ReactElement } from 'react'

import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  CloseButton,
  useBoolean,
  VStack,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../context/auth-modal/context'
import { useDashboardNavigationContext } from '../../context/dashboard-navigation/context'

const BANNER_COLOR = '#6277D7'
interface Props {
  isAuthed: boolean
}

const MobileBanner = ({ isAuthed }: Props): ReactElement => {
  const { openAuthModal } = useAuthModalContext()
  const { toggleOverviewModalOpen } = useDashboardNavigationContext()

  const [isViewingBanner, { off: handleCloseBanner }] = useBoolean(true)

  const handleGoToWhatIsThis = () => {
    toggleOverviewModalOpen()
  }

  if (!isViewingBanner) {
    return <></>
  }

  return (
    <Flex
      w="100%"
      h="max-content"
      bg={BANNER_COLOR}
      p={0}
      justify="center"
      pos="relative"
      opacity={0.9}
    >
      <VStack p={5} align="flex-start" spacing={4} w="100%">
        <Box color="white">
          <Text fontWeight={'semibold'} fontSize="16" mb={1}>
            Signalist is best on desktops
          </Text>
          <Text fontSize="12">
            {' '}
            Some features are not available on mobile devices.
          </Text>
        </Box>

        {!isAuthed && (
          <HStack spacing={5} h="100%">
            <Button
              variant="outline"
              size={'sm'}
              _hover={{ bg: '#ffffff85' }}
              onClick={handleGoToWhatIsThis}
              color="white"
              fontSize={12}
            >
              What is Signalist?
            </Button>
            <Button
              bg="white"
              size={'sm'}
              color={BANNER_COLOR}
              onClick={() => openAuthModal('signUp')}
              fontSize={12}
            >
              Sign Up
            </Button>
          </HStack>
        )}
      </VStack>
      <CloseButton
        pos="absolute"
        right="0.5rem"
        top="0.5rem"
        color="white"
        onClick={handleCloseBanner}
      />
    </Flex>
  )
}

export default MobileBanner
