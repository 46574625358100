import { useCallback, useEffect, useState } from 'react'
import { useBoolean } from '@chakra-ui/react'

interface Props {
  collapsedWidth: number
  initialWidth: number
  maxWidth: number
  minExpandedWidth: number
}

interface UseResizeReturn {
  enableResize: () => void
  isCollapsed: boolean
  isResizing: boolean
  onExpand: () => void
  width: number
}

const useResize = ({
  collapsedWidth,
  initialWidth,
  maxWidth,
  minExpandedWidth,
}: Props): UseResizeReturn => {
  const [isResizing, setIsResizing] = useBoolean()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [width, setWidth] = useState(initialWidth)
  const [hasCrossedThreshold, setHasCrossedThreshold] = useState(false)

  const resize = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        e.preventDefault()

        // IMPORTANT: If you need to understand this, call Cooper

        const newWidth = e.clientX // You may want to add some offset here from props
        const collapseThreshold = minExpandedWidth - 50
        const expandThreshold = collapsedWidth + 50
        if (
          (!isCollapsed && newWidth >= collapseThreshold) ||
          (isCollapsed && newWidth < expandThreshold)
        ) {
          setHasCrossedThreshold(false)
        }
        if (newWidth >= minExpandedWidth && newWidth < maxWidth) {
          setWidth(newWidth)
        } else if (
          newWidth < collapseThreshold &&
          !isCollapsed &&
          !hasCrossedThreshold
        ) {
          setIsCollapsed(true)
          setHasCrossedThreshold(true)
          setWidth(collapsedWidth)
        } else if (
          newWidth > expandThreshold &&
          isCollapsed &&
          !hasCrossedThreshold
        ) {
          setIsCollapsed(false)
          setHasCrossedThreshold(true)
          setWidth(Math.max(minExpandedWidth, newWidth))
        }
      }
    },
    [
      collapsedWidth,
      isCollapsed,
      isResizing,
      maxWidth,
      minExpandedWidth,
      hasCrossedThreshold,
    ]
  )

  const onExpand = useCallback(() => {
    setIsCollapsed(false)
    setWidth(initialWidth)
  }, [initialWidth])

  useEffect(() => {
    const resetListener = () => {
      setHasCrossedThreshold(false)
      setIsResizing.off()
    }
    document.addEventListener('mousemove', resize)
    document.addEventListener('mouseup', resetListener)

    return () => {
      document.removeEventListener('mousemove', resize)
      document.removeEventListener('mouseup', resetListener)
    }
  }, [resize, setHasCrossedThreshold, setIsResizing])

  return {
    enableResize: setIsResizing.on,
    isCollapsed,
    isResizing,
    onExpand,
    width,
  }
}

export default useResize
