/**
 * Navigation bar, rendered on any page that uses PageLayout
 */
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'

import { Box, Divider, Flex, VStack } from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../../../../context/dashboard-navigation/context'
import * as constants from '../../../../../utils/constants'
import db from '../../../../../utils/firebase/firestore'
import { MARGIN } from '../../utils'
import DashboardsListItem from './DashboardsListItem'
import { DashboardAuthor } from '../../../../dashboard/dashboardDisplay/DashboardDisplayV3'
import { useCurrentUser } from '../../../../../context/user/context'
import { useNotificationsContext } from '../../../../../context/notifications/context'

export interface DashboardDraggableData {
  id: string
  title: string
  author: DashboardAuthor
}

interface Props {
  onCollapseIfMobile: (eventHandler?: () => void) => void
}

const DashboardsList = ({ onCollapseIfMobile }: Props): ReactElement => {
  const { user } = useCurrentUser()
  const userId = user?.uid
  const { dashboards, selectedDashboardId } = useDashboardNavigationContext()

  const [dashboardOverviews, setDashboardOverviews] = useState<
    DashboardDraggableData[]
  >([])

  const itemElement =
    typeof window === 'undefined'
      ? null
      : document.getElementById(
          constants.dashboardsListItemId(selectedDashboardId ?? '')
        )

  useEffect(() => {
    const listElement = document.getElementById('dashboards-list')

    const observer = new IntersectionObserver(
      ([entry]) => {
        observer.unobserve(entry.target)
        const onScreen = entry.isIntersecting
        if (!onScreen) {
          itemElement?.scrollIntoView()
        }
      },
      {
        root: listElement,
        rootMargin: '0px',
        threshold: 1.0,
      }
    )
    if (itemElement != null) {
      observer.observe(itemElement)
    }
  }, [itemElement])

  useEffect(() => {
    const dashboardOverviews: DashboardDraggableData[] =
      dashboards?.map((dashboard) => {
        return {
          id: dashboard.id,
          title: dashboard.title,
          author: dashboard.author,
        }
      }) ?? []
    setDashboardOverviews(dashboardOverviews)
  }, [dashboards])

  const save = (newDashboardOverviews: DashboardDraggableData[]) => {
    // Save new order
    const newIdsOrder = newDashboardOverviews.map((dashboard) => dashboard.id)
    db.doc(`users/${userId}`).update({ dashboardIdsInOrder: newIdsOrder })
  }

  const reorder = (
    list: DashboardDraggableData[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      dashboardOverviews,
      result.source.index,
      result.destination.index
    )

    setDashboardOverviews(items)

    save(items)
  }

  const { dashboardBadgeNumberMap } = useNotificationsContext()

  const dashboardButtons = useMemo(() => {
    const buttons = dashboardOverviews.map((dashboard, idx) => (
      <DashboardsListItem
        badgeNumber={dashboardBadgeNumberMap.get(dashboard.id)}
        onCollapseIfMobile={onCollapseIfMobile}
        dashboard={dashboard}
        key={idx}
        index={idx}
      />
    ))
    return buttons
  }, [dashboardBadgeNumberMap, dashboardOverviews, onCollapseIfMobile])

  // const draggingCustomStyle = (): React.CSSProperties => {
  //   const style: React.CSSProperties = {
  //     marginTop: -2,
  //     paddingTop: 6,
  //     paddingBottom: 1,
  //     width: '100%',
  //     overflowY: 'scroll',
  //   }
  //   if (style.transform) {
  //     const axisLockY =
  //       'translate(0px' +
  //       style.transform.slice(
  //         style.transform.indexOf(','),
  //         style.transform.length
  //       )
  //     return {
  //       ...style,
  //       transform: axisLockY,
  //     }
  //   }
  //   return style
  // }

  // const scrollRef = React.useRef(null)
  // const { y: scrollOffset } = useScroll(scrollRef)
  // // console.log(scrollOffset)

  //const [scrollOffset, setScrollOffset] = useState(0)
  return (
    <>
      <Divider
        borderColor={'gray.300'}
        w={`calc(100% - ${MARGIN * 4 * 2}px)`}
        mx={MARGIN}
      />

      <VStack pt={4} align="stretch" spacing={0} overflow="hidden" w="100%">
        <Flex overflowY="auto">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable isDropDisabled={false} droppableId="droppable">
              {(provided) => (
                <Box
                  id="dashboards-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // onScroll={(x) => setScrollOffset(x.currentTarget.scrollTop)}
                  //",
                  w="100%"
                  overflowY="scroll"
                  overflowX="hidden"
                  // boxShadow:
                  //   scrollOffset > 0 ? '0 1em 1em -1em gray inset' : '',
                >
                  {dashboardButtons}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
      </VStack>
    </>
  )
}
export default DashboardsList
