import React, { ReactElement } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
} from '@chakra-ui/react'

import { useCurrentUser } from '../../context/user/context'

interface FeedbackFormProps {
  feedbackText: string
  onFeedbackChange: (text: string) => void
  onFeedbackFormSubmit: (name: string, email: string, feedback: string) => void
}

const FeedbackForm = ({
  feedbackText,
  onFeedbackChange,
  onFeedbackFormSubmit,
}: FeedbackFormProps): ReactElement => {
  const { user } = useCurrentUser()

  return (
    <VStack
      spacing={4}
      paddingRight="4"
      paddingLeft="4"
      w="100%"
      // align="flex-end"
      align="start"
      paddingTop="2"
      paddingBottom="2"
    >
      <FormControl>
        <FormLabel fontSize="md">Your message</FormLabel>
        <Textarea
          minH="200px"
          id="feedback"
          name="feedback"
          borderRadius="8"
          onChange={(e) => onFeedbackChange(e.target.value)}
          resize="none"
          size="md"
          value={feedbackText}
          spellCheck={false}
          placeholder="Share your thoughts"
          variant="outline"
          w="100%"
        />
      </FormControl>

      <Button
        w="100%"
        size="sm"
        isDisabled={feedbackText.length == 0 && user != undefined}
        colorScheme="blue"
        onClick={() => {
          if (user != undefined)
            onFeedbackFormSubmit(user.displayName, user.email, feedbackText)
        }}
      >
        Submit
      </Button>
    </VStack>
  )
}

export default FeedbackForm
