import { ReactElement, useCallback, useEffect } from 'react'
import { FiEdit2 } from 'react-icons/fi'

import { AddIcon, CheckIcon } from '@chakra-ui/icons'
import { Box, IconButton, SlideFade, Tooltip, VStack } from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../../context/dashboard-navigation/context'
import { useCurrentUser } from '../../../context/user/context'
import db from '../../../utils/firebase/firestore'

export type DashboardLayouts = ReactGridLayout.Layouts

const EditDashboard = (): ReactElement => {
  const {
    isEditingDashboard: isEditingLayout,
    setIsEditingDashboard,
    selectedDashboard: dashboard,
    handleOpenAddWidgetModal,
    selectedDashboardLayouts: editedLayouts,
    setSelectedDashboardLayouts: setEditedLayouts,
  } = useDashboardNavigationContext()

  const { user } = useCurrentUser()
  const userId = user?.uid

  // Initialize layouts and title once a dashboard is loaded
  useEffect(() => {
    if (dashboard != null) {
      setEditedLayouts(dashboard.layouts)
    }
    return () => {
      setEditedLayouts(undefined)
    }
  }, [dashboard, setEditedLayouts])

  const author = dashboard?.author
  const isAuthor = userId != null && author?.id === userId

  const toggleEditingDashboard = useCallback(
    () => setIsEditingDashboard((wasEditing) => !wasEditing),
    [setIsEditingDashboard]
  )

  // save author-only-editable settings: title, layouts
  const handleSaveLayout = useCallback(async (): Promise<unknown> => {
    const cleanLayoutsObject = cleanLayouts(editedLayouts)
    setEditedLayouts(cleanLayoutsObject)
    if (dashboard?.id == undefined) return
    return db.doc(`dashboards/${dashboard.id}`).update({
      layouts: cleanLayoutsObject,
    })
  }, [editedLayouts, dashboard?.id, setEditedLayouts])

  // remove properties deep within the layouts object that are undefined
  // otherwise, firebase errors out when updating
  const cleanLayouts = (
    layouts: DashboardLayouts | undefined
  ): DashboardLayouts | undefined => {
    if (layouts == undefined) {
      return undefined
    }

    const cleanLayoutsObject: Record<string, ReactGridLayout.Layout[]> = {}

    Object.keys(layouts).forEach((breakpoint: keyof DashboardLayouts) => {
      const cleanBreakpointArray = layouts[breakpoint].map(
        (widgetLayout: ReactGridLayout.Layout) => {
          const keys = Object.keys(widgetLayout) as Array<
            keyof ReactGridLayout.Layout
          >
          keys.forEach((layoutProp) => {
            widgetLayout[layoutProp] == undefined &&
              delete widgetLayout[layoutProp]
          })
          return widgetLayout
        }
      )
      if (cleanBreakpointArray.length > 0) {
        cleanLayoutsObject[breakpoint] = cleanBreakpointArray
      }
    })

    return cleanLayoutsObject
  }

  const handleClickSave = useCallback(() => {
    handleSaveLayout().then(() => setIsEditingDashboard(false))
  }, [handleSaveLayout, setIsEditingDashboard])

  const handleClickAddWidget = useCallback(() => {
    handleSaveLayout()
    handleOpenAddWidgetModal()
  }, [handleOpenAddWidgetModal, handleSaveLayout])

  if (!isAuthor) {
    return <></>
  }

  return (
    <Box pb="2">
      {isEditingLayout ? (
        <SlideFade in={isEditingLayout}>
          <VStack spacing={4}>
            <Tooltip placement="left" label={'Done editing'} openDelay={1000}>
              <IconButton
                onClick={handleClickSave}
                size="lg"
                isRound={true}
                icon={<CheckIcon fontSize="xl" color="white" />}
                colorScheme="green"
                aria-label="Save changes"
                boxShadow="0 0.75px 2.5px rgb(0 0 0 / 0.5)"
              />
            </Tooltip>
            <Tooltip placement="left" label={'Add widget'} openDelay={1000}>
              <IconButton
                onClick={handleClickAddWidget}
                size="lg"
                isRound={true}
                icon={<AddIcon fontSize="lg" color="white" />}
                colorScheme="blue"
                aria-label="Add widget"
                boxShadow="0 0.75px 2.5px rgb(0 0 0 / 0.5)"
              />
            </Tooltip>
          </VStack>
        </SlideFade>
      ) : (
        <Tooltip placement="left" label={'Edit dashboard'} openDelay={1000}>
          <IconButton
            onClick={toggleEditingDashboard}
            size="lg"
            isRound={true}
            icon={<FiEdit2 size={20} />}
            colorScheme="blue"
            aria-label="Help button"
            boxShadow="0 0.75px 2.5px rgb(0 0 0 / 0.5)"
          />
        </Tooltip>
      )}
    </Box>
  )
}

export default EditDashboard
