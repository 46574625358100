import { useRouter } from 'next/router'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { BiDislike, BiLike } from 'react-icons/bi'
import { BsPersonCheck, BsReplyAll } from 'react-icons/bs'
import { FaRegComments } from 'react-icons/fa'
import { IoNotificationsOutline } from 'react-icons/io5'

import {
  Box,
  Center,
  Circle,
  Flex,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Portal,
  Spacer,
  Spinner,
  Square,
  Text,
  VStack,
  useOutsideClick,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../../../context/auth-modal/context'
import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'
import {
  Notif,
  useNotificationsContext,
} from '../../../../context/notifications/context'
import { routes } from '../../../../utils/constants'
import firebase from '../../../../utils/firebase/firebase'
import db from '../../../../utils/firebase/firestore'
import { Dashboard } from '../../../dashboard/dashboardDisplay/DashboardDisplayV3'
import { MARGIN } from '../utils'
import { iconSize } from '.'

interface Props {
  isCollapsed?: boolean
}

const Activity = ({ isCollapsed = false }: Props): ReactElement => {
  const { activityBadgeNumber, notifications, recordNotificationsPopoverView } =
    useNotificationsContext()
  const { openAuthModal } = useAuthModalContext()

  const [isOpen, setIsOpen] = useState(false)
  const [canOpen, setCanOpen] = useState(true)

  const onClose = () => {
    setCanOpen(false)
    if (isOpen) {
      setIsOpen(false)
      recordNotificationsPopoverView()
    }
    setTimeout(() => setCanOpen(true), 100)
  }

  const onClickOpen = () => {
    setIsOpen(true)
  }

  const ref = React.useRef(null as null | HTMLDivElement)

  useOutsideClick({
    ref: ref,
    handler: () => isOpen && onClose(),
  })

  const isAuthed = firebase.auth().currentUser != null

  return (
    <Popover
      arrowSize={14}
      arrowPadding={0}
      offset={[-60, 10]}
      isLazy
      isOpen={isOpen}
      placement="right-end"
    >
      <>
        <PopoverAnchor>
          <Box
            bg={activityBadgeNumber == 0 ? 'inherit' : 'red.50'}
            p={3}
            rounded={'md'}
            _hover={{
              bg: activityBadgeNumber == 0 ? 'gray.100' : 'red.100',
              cursor: 'pointer',
            }}
            fontSize={'18px'}
            lineHeight={'10px'}
            fontWeight={'medium'}
            onClick={() => {
              if (!isAuthed) {
                openAuthModal('signUp')
              } else if (!isOpen && canOpen) {
                onClickOpen()
              }
            }}
          >
            <HStack mx={MARGIN - 4} spacing={3}>
              <IoNotificationsOutline size={iconSize} />
              {!isCollapsed && (
                <>
                  <Text>Activity</Text>
                  <Spacer />
                  {activityBadgeNumber > 0 && (
                    <Circle size="24px" bg="red.500" color="white">
                      <Text fontSize="sm">{activityBadgeNumber}</Text>
                    </Circle>
                  )}
                </>
              )}
            </HStack>
          </Box>
        </PopoverAnchor>
        <Portal>
          <PopoverContent w="400px">
            <PopoverHeader px={4}>
              <VStack align="stretch">
                <HStack>
                  <Text fontSize="xl" as="b">
                    Notifications
                  </Text>
                  <Spacer />
                  {/* <Button color="black" fontSize="sm" variant="link">
                    Mark all as read
                  </Button> */}
                </HStack>
                {/* <HStack>
                  <div>Unread</div>
                  <div>All</div>
                </HStack> */}
              </VStack>
            </PopoverHeader>
            <PopoverArrow />
            <PopoverBody
              p={0}
              minH="200px"
              maxH="min(70vh, 500px)"
              overflow="scroll"
              ref={ref}
            >
              <VStack spacing={0} p={0} w="100%">
                {notifications.map((notif) => (
                  <ActivityRow
                    key={notif.id}
                    onClosePopover={onClose}
                    notif={notif}
                  />
                ))}{' '}
              </VStack>

              {notifications.length == 0 && (
                <HStack
                  flexDir={'column'}
                  color="gray.500"
                  justify={'center'}
                  p={4}
                >
                  <Text>No notifications.</Text>
                </HStack>
              )}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </>
    </Popover>
  )
}
export default React.memo(Activity)

const formatNumAndUnits = (
  count: number,
  unit: string,
  plural?: string
): string | undefined => {
  if (count <= 0) return undefined
  if (count == 1) return `1 ${unit}`
  plural = plural ?? `${unit}s`
  return `${count} ${plural}`
}

// const formatUnits = (count: number, unit: string): string => {
//   if (count == 1) return unit
//   else return `${unit}s`
// }

type status = 'pending' | 'success' | 'error'
interface UseDashboardReturn {
  dashboard: Dashboard | undefined
  status: status
}

const useDashboard = (id: string): UseDashboardReturn => {
  const { dashboards } = useDashboardNavigationContext()

  const [dashboard, setDashboard] = useState<Dashboard | undefined>()
  const [status, setStatus] = useState<status>('pending')

  useEffect(() => {
    setDashboard(undefined)
    setStatus('pending')
    const dashboardFromContext = dashboards?.find(
      (dashboard) => dashboard.id === id
    )
    if (dashboardFromContext) {
      setDashboard(dashboardFromContext)
      setStatus('success')
    } else {
      db.doc(`dashboards/${id}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setDashboard(doc.data() as Dashboard)
            setStatus('success')
          } else {
            setDashboard(undefined)
            setStatus('error')
          }
        })
        .catch(() => {
          setDashboard(undefined)
          setStatus('error')
        })
    }
  }, [dashboards, id])

  return { dashboard, status }
}

interface ActivityRowWrapperProps {
  children: React.ReactChild
  onClickRoute?: string
  onClosePopover: () => void
  notif: Notif
}

const ActivityRowWrapper = ({
  notif,
  onClickRoute,
  onClosePopover,
  children,
}: ActivityRowWrapperProps) => {
  const { markNotificationAsRead } = useNotificationsContext()
  const icon = useMemo((): ReactElement => {
    switch (notif.type) {
      case 'thread-reply':
        return <BsReplyAll size="24px" />
      case 'top-level-comment':
        return <FaRegComments size="24px" />
      case 'dashboard-follow':
        return <BsPersonCheck size="24px" />
      case 'comment-reaction':
        const { dislikes, likes } = notif.reactions
        return likes >= dislikes ? (
          <BiLike size="24px" />
        ) : (
          <BiDislike size="24px" />
        )
    }
  }, [notif])

  const router = useRouter()
  const onClick = () => {
    router.push(onClickRoute ?? routes.dashboard(notif.dashboardId))
  }

  return (
    <Box
      bg={notif.isUnread ? 'red.50' : 'inherit'}
      _hover={{ bg: 'gray.100', cursor: 'pointer' }}
      py={2}
      px={3}
      onClick={() => {
        onClick()
        onClosePopover()
        markNotificationAsRead(notif.id)
      }}
      w="100%"
    >
      <Flex align="center" direction="row" pos="relative" w="100%">
        {notif.isUnread && (
          <Circle right="2%" pos="absolute" size="6px" bg="red.500" />
        )}
        <Square ml={3} mr={3} size="30px">
          {icon}
        </Square>
        <Flex ml={2} mr={6} w="max">
          {children}
        </Flex>
      </Flex>
    </Box>
  )
}

interface ActivityRowProps {
  onClosePopover: () => void
  notif: Notif
}
const ActivityRow = ({
  onClosePopover,
  notif,
}: ActivityRowProps): ReactElement | null => {
  const { dashboard, status } = useDashboard(notif.dashboardId)

  if (status == 'error') {
    return null
  }
  if (status == 'pending') {
    return (
      <Center py={2} w="100%">
        <Spinner />
      </Center>
    )
  }
  if (dashboard == undefined) return null

  const getRowContent = (
    notif: Notif,
    dashboardTitle: string
  ): React.ReactElement | null => {
    if (notif.type === 'top-level-comment') {
      const num = notif.commentIds.length
      if (num == 0) return null
      const commentsText = formatNumAndUnits(num, 'new comment')
      return (
        <ActivityRowWrapper onClosePopover={onClosePopover} notif={notif}>
          <Text>
            {commentsText} in <b>{dashboardTitle}</b>
          </Text>
        </ActivityRowWrapper>
      )
    }
    if (notif.type === 'dashboard-follow') {
      const numFollows = notif.followerIds.length
      const followersFormatted = formatNumAndUnits(
        numFollows,
        'person',
        'people'
      )
      return (
        <ActivityRowWrapper onClosePopover={onClosePopover} notif={notif}>
          <Text>
            {followersFormatted} followed your dashboard <b>{dashboardTitle}</b>
          </Text>
        </ActivityRowWrapper>
      )
    }
    if (notif.type == 'comment-reaction') {
      const { dislikes, likes } = notif.reactions
      const likesString = formatNumAndUnits(likes, 'new like')
      const dislikesString = formatNumAndUnits(dislikes, 'new dislike')
      const formatted = [likesString, dislikesString]
        .filter((x) => x != undefined)
        .join(' and ')

      if (formatted == '') return null
      const route = routes.dashboard(notif.dashboardId, notif.parentCommentId)

      return (
        <ActivityRowWrapper
          onClickRoute={route}
          onClosePopover={onClosePopover}
          notif={notif}
        >
          <Text>
            {formatted} on your comment in <b>{dashboardTitle}</b>
          </Text>
        </ActivityRowWrapper>
      )
    }
    if (notif.type == 'thread-reply') {
      const repliesString = formatNumAndUnits(
        notif.commentIds.length,
        'new reply',
        'new replies'
      )

      const route = routes.dashboard(notif.dashboardId, notif.parentCommentId)

      return (
        <ActivityRowWrapper
          onClickRoute={route}
          onClosePopover={onClosePopover}
          notif={notif}
        >
          <Text>
            {repliesString} to your comment on <b>{dashboardTitle}</b>
          </Text>
        </ActivityRowWrapper>
      )
    }
    return null
  }
  return getRowContent(notif, dashboard.title)
}
