import { ReactElement } from 'react'

import { VStack } from '@chakra-ui/react'

import EditDashboard from './EditDashboard'
import HelpPopover from './HelpPopover'

const Toolbar = (): ReactElement => {
  return (
    <VStack zIndex={50}>
      <EditDashboard />
      <HelpPopover />
    </VStack>
  )
}

export default Toolbar
