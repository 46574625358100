import React, { ReactElement } from 'react'
import { isMobile } from 'react-device-detect'

import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  CloseButton,
  useBoolean,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../context/auth-modal/context'
import { useDashboardNavigationContext } from '../../context/dashboard-navigation/context'

const BANNER_COLOR = '#6277D7'

const UnauthedBanner = (): ReactElement => {
  const { openAuthModal } = useAuthModalContext()
  const { toggleOverviewModalOpen } = useDashboardNavigationContext()

  const [isViewingBanner, { off: handleCloseBanner }] = useBoolean(true)

  const handleGoToWhatIsThis = () => {
    toggleOverviewModalOpen()
  }

  if (!isViewingBanner) {
    return <></>
  }

  return (
    <Flex
      w="100%"
      h="max-content"
      bg={BANNER_COLOR}
      p={0}
      justify="center"
      pos="relative"
      opacity={isMobile ? 0.9 : 1}
    >
      <Wrap
        color="white"
        spacing={5}
        p={5}
        verticalAlign={'center'}
        w={isMobile ? '98%' : '95%'}
        maxW="1000px"
        justify={!isMobile ? 'auto' : 'center'}
      >
        <WrapItem mr={10}>
          <Box>
            <Text fontWeight={'semibold'} fontSize="18">
              Refresh data, pin your favorite dashboards, join cool discussions.
            </Text>
            <Text>Do it all from your Signalist account.</Text>
          </Box>
          <Spacer w={10} />
        </WrapItem>

        <WrapItem>
          <HStack spacing={5} h="100%">
            <Button
              variant="outline"
              _hover={{ bg: '#ffffff85' }}
              onClick={handleGoToWhatIsThis}
            >
              What is Signalist?
            </Button>
            <Button
              bg="white"
              color={BANNER_COLOR}
              onClick={() => openAuthModal('signUp')}
            >
              Sign Up
            </Button>
          </HStack>
        </WrapItem>
      </Wrap>
      <CloseButton
        pos="absolute"
        right="0.5rem"
        top="0.5rem"
        color="white"
        onClick={handleCloseBanner}
      />
    </Flex>
  )
}

export default UnauthedBanner
