/**
 * Navigation bar, rendered on any page that uses PageLayout
 */

import { useRouter } from 'next/router'
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { AiFillHome, AiOutlineHome } from 'react-icons/ai'
import {
  BsArrowBarLeft,
  BsArrowBarRight,
  BsPlusSquare,
  BsSearch,
} from 'react-icons/bs'
import { IoSettingsOutline } from 'react-icons/io5'

import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../../../context/auth-modal/context'
import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'
import { useSettingsModalActions } from '../../../../context/settings-modal/context'
import { useCurrentUser } from '../../../../context/user/context'
import { routes } from '../../../../utils/constants'
// import { CgAddR } from 'react-icons/cg'
import NavLink from '.././NavLink'
import { MARGIN, MOBILE_WIDTH } from '../utils'
import Activity from './Activity'
// import { VscDiffAdded } from 'react-icons/vsc'
import DashboardsList from './DashboardsList'
import ProfileDisplay from './ProfileDisplay'
import useResize from './useResize'

interface Props {
  isMobileCollapsed?: boolean
  onMobileCollapse: () => void
  isMobile: boolean
}
// TODO: use selected and unselected
export const iconSize = 24
interface NavLink {
  allowUnauthed: boolean
  link: string
  name: string
  unselectedIcon: JSX.Element
  selectedIcon: JSX.Element
}
export const links: NavLink[] = [
  {
    allowUnauthed: false,
    link: routes.home,
    name: 'Home',
    unselectedIcon: <AiOutlineHome size={iconSize} />,
    selectedIcon: <AiFillHome size={iconSize} />,
  },
  {
    allowUnauthed: true,
    link: routes.discover,
    name: 'Discover',
    unselectedIcon: <BsSearch size={iconSize} />,
    selectedIcon: <BsSearch size={iconSize} />,
  },
]

const Sidebar = ({
  isMobileCollapsed,
  onMobileCollapse,
  isMobile,
}: Props): ReactElement => {
  const { createNewDashboard } = useDashboardNavigationContext()
  const { openSettings } = useSettingsModalActions()
  const { openAuthModal } = useAuthModalContext()

  const maxWidth = 350

  const {
    enableResize,
    isCollapsed: isDesktopCollapsed,
    isResizing,
    onExpand,
    width: nonMobileWidth,
  } = useResize({
    collapsedWidth: 60,
    initialWidth: 260,
    maxWidth,
    minExpandedWidth: 260,
  })

  const isCollapsed = isMobile ? isMobileCollapsed : isDesktopCollapsed
  const width = isMobile ? MOBILE_WIDTH : nonMobileWidth

  useEffect(() => {
    if (isMobile) {
      onMobileCollapse()
    }
  }, [isMobile, onMobileCollapse])

  // collapse the sidebar when routing if mobile
  const collapseIfMobile = useCallback(
    (onClick?: () => void) => {
      if (isMobile) {
        onMobileCollapse()
      }
      onClick != undefined && onClick()
    },
    [isMobile, onMobileCollapse]
  )

  const { pathname } = useRouter()

  const { user } = useCurrentUser()
  const isAuthed = user != null

  const fullContent = useMemo(
    () => (
      <Flex
        align="flex-start"
        direction="column"
        h="100%"
        overflowY="hidden"
        py={MARGIN}
        pt={0}
        m={0}
        maxW="100%"
        zIndex={100}
        borderRightWidth="3px"
        bg="white"
      >
        <Flex align={'flex-end'} w="100%" maxW="100%">
          <Spacer />
          {isMobile && (
            <IconButton
              aria-label="Collapse"
              mt={4}
              mb={5}
              py={4}
              px={2}
              mx={MARGIN}
              icon={<BsArrowBarLeft size={iconSize} />}
              onClick={onMobileCollapse}
            />
          )}
        </Flex>

        {!isMobile && (
          <>
            <VStack align="stretch" spacing={0} p={0} w="100%">
              <Center bg="#F3F3F3" w="100%" py={1} mb={1}>
                <Text color="gray.500" fontWeight={'500'}>
                  beta v1.0
                </Text>
              </Center>
              <VStack align="stretch" spacing={1} pb={4} px={2} pt={2} w="100%">
                {links.map((l) => {
                  return (
                    <NavLink
                      key={l.link}
                      href={isAuthed || l.allowUnauthed ? l.link : undefined}
                      isSelected={pathname.indexOf(l.link) === 0}
                      onClick={
                        isAuthed || l.allowUnauthed
                          ? undefined
                          : () => openAuthModal('signUp')
                      }
                    >
                      <HStack mx={MARGIN - 4} spacing={3}>
                        {l.unselectedIcon}
                        <Text>{l.name}</Text>
                      </HStack>
                    </NavLink>
                  )
                })}
                <Activity />
              </VStack>
            </VStack>
            <VStack align="stretch" spacing={2} w="100%" py={4} px={2}>
              <NavLink
                onClick={
                  isAuthed ? createNewDashboard : () => openAuthModal('signUp')
                }
              >
                <HStack mx={MARGIN - 4} spacing={3}>
                  <BsPlusSquare size={iconSize - 2} />
                  <Text>Create Dashboard</Text>
                </HStack>
              </NavLink>
            </VStack>
          </>
        )}

        <DashboardsList onCollapseIfMobile={collapseIfMobile} />

        <Spacer />
        {!isAuthed && (
          <Flex
            p={MARGIN}
            pt={4}
            pb={0}
            w="100%"
            pr={4}
            align="center"
            maxW="100%"
          >
            <Avatar
              mr={3}
              size="sm"
              _hover={{ cursor: 'pointer' }}
              onClick={() => openAuthModal('signUp')}
              bg="gray.400"
            />
            <HStack w="100%">
              <Button
                colorScheme="blue"
                onClick={() => openAuthModal('signUp')}
                variant="link"
              >
                Sign up
              </Button>
              <Text textColor="gray.800">or</Text>
              <Button
                colorScheme="blue"
                onClick={() => openAuthModal('logIn')}
                variant="link"
              >
                Log in
              </Button>
            </HStack>
          </Flex>
        )}
        {isAuthed && (
          <Flex
            p={MARGIN}
            pt={4}
            pb={0}
            w="100%"
            pr={4}
            align="center"
            maxW="100%"
          >
            <ProfileDisplay collapseIfMobile={collapseIfMobile} />
            <Spacer />
            <IconButton
              aria-label="Settings"
              onClick={() => collapseIfMobile(openSettings)}
              icon={<IoSettingsOutline size={26} />}
              bg="white"
              ml={3}
              mr={0}
            />
          </Flex>
        )}
      </Flex>
    ),
    [
      isMobile,
      onMobileCollapse,
      collapseIfMobile,
      isAuthed,
      pathname,
      openAuthModal,
      createNewDashboard,
      openSettings,
    ]
  )

  const collapsedContent = useMemo(
    () =>
      isMobile ? (
        <></>
      ) : (
        <Flex
          align="center"
          flexDir="column"
          h="100%"
          py={MARGIN}
          pt={MARGIN - 3}
          // boxShadow="0 0 5px rgb(0 0 0 / 0.35)"
          borderRightWidth="3px"
          bg="white"
        >
          <VStack align="stretch" spacing={1} w="100%" p={4} pt={2}>
            {links.map((l) => (
              <NavLink
                key={l.link}
                href={isAuthed || l.allowUnauthed ? l.link : undefined}
                isSelected={l.link == pathname}
                onClick={() =>
                  collapseIfMobile(
                    isAuthed || l.allowUnauthed
                      ? undefined
                      : () => openAuthModal('signUp')
                  )
                }
              >
                <HStack mx={MARGIN - 4} spacing={3}>
                  {l.unselectedIcon}
                </HStack>
              </NavLink>
            ))}
            <Activity isCollapsed />
          </VStack>
          <VStack align="stretch" spacing={2} w="100%" p={4}>
            <NavLink
              onClick={() =>
                collapseIfMobile(
                  isAuthed ? createNewDashboard : () => openAuthModal('signUp')
                )
              }
            >
              <HStack mx={MARGIN - 4} spacing={3}>
                <BsPlusSquare size={iconSize - 2} />
              </HStack>
            </NavLink>
          </VStack>

          <IconButton
            aria-label="Expand"
            mt={4}
            mb={5}
            bg="none"
            p={4}
            icon={<BsArrowBarRight size={iconSize} />}
            onClick={onExpand}
          />
          <Spacer />
          {!isAuthed && (
            <Avatar
              _hover={{ cursor: 'pointer' }}
              onClick={() => openAuthModal('signUp')}
              size="sm"
              bg="gray.400"
            />
          )}
          {isAuthed && (
            <>
              <IconButton
                aria-label="Settings"
                bg="none"
                onClick={() => openSettings()}
                icon={<IoSettingsOutline size={26} />}
                ml={0}
                mb={4}
              />
              <ProfileDisplay
                collapseIfMobile={collapseIfMobile}
                variant="collapsed"
              />
            </>
          )}
        </Flex>
      ),
    [
      isMobile,
      onExpand,
      isAuthed,
      collapseIfMobile,
      pathname,
      openAuthModal,
      createNewDashboard,
      openSettings,
    ]
  )

  return (
    <Box h="100%" pos={'relative'}>
      <Flex
        h="100%"
        bg="gray.100"
        flexDir="column"
        w={isCollapsed ? 'max-content' : width}
      >
        {isCollapsed && collapsedContent}
        {!isCollapsed && fullContent}
      </Flex>
      {!isMobile && (
        <Box
          zIndex={100}
          position="absolute"
          justifyContent="center"
          alignContent="center"
          bg="blue.500"
          width="4px"
          top="0"
          right="0px"
          bottom="0"
          opacity={isResizing ? 1 : 0}
          cursor={
            isCollapsed
              ? 'e-resize'
              : width > maxWidth - 3
              ? 'w-resize'
              : 'col-resize'
          }
          _hover={{
            opacity: 1,
            transitionDelay: '0.2s',
            transitionDuration: '0.2s',
          }}
          onMouseDown={enableResize}
        />
      )}
    </Box>
  )
}
export default React.memo(Sidebar)
