import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

import { Box, Flex, HStack, useBoolean, useMediaQuery } from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../context/dashboard-navigation/context'
import firebase from '../../utils/firebase/firebase'
import Toolbar from './Toolbar'
import UnauthedBanner from './UnauthedBanner'
import MobileAppBar from './navigation/MobileAppBar'
import MobileBanner from './MobileBanner'
import Sidebar from './navigation/Sidebar'
import { isMobile } from 'react-device-detect'
import { use100vh } from 'react-div-100vh'

interface Props {
  children: ReactNode
  //listenToScroll?: boolean
}
const PageLayout = ({
  children,
}: //listenToScroll = false,
Props): ReactElement => {
  // const { setScrollPosition } = useDashboardNavigationContext()
  // const user = useAuthUser()
  // const shouldShowSidebar = user.id != null
  // const handleScroll = (e: React.UIEvent<HTMLElement>) => {
  //   if (!listenToScroll) {
  //     return
  //   }
  //   const element = e.currentTarget
  //   setScrollPosition(element.scrollTop)
  // }

  const { isViewingComments } = useDashboardNavigationContext()

  const [
    isMobileExpanded,
    { off: handleMobileCollapse, toggle: handleToggleMobileExpanded },
  ] = useBoolean(true)
  const [isAuthed, setIsAuthed] = useState(true)

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      setIsAuthed(authUser != null)
    })
    return unsubscribe
  }, [])

  const [isMobileWithoutComments] = useMediaQuery(`(max-width: ${840}px)`)
  const [isMobileWithComments] = useMediaQuery(`(max-width: ${1200}px)`)

  const isMobileSize = isViewingComments
    ? isMobileWithComments
    : isMobileWithoutComments

  useEffect(() => {
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }, [])

  const height = use100vh()
  const componentHeight = !isMobile || height == null ? '100vh' : height

  return (
    <>
      <Flex
        w="100vw"
        h={componentHeight}
        overflowY={'hidden'}
        flexDir="column"
        // pb={isMobile && isSafari ? '10rem' : '0'}
      >
        <Box>
          {isMobileSize ? (
            <MobileAppBar
              onToggleSidebarExpanded={handleToggleMobileExpanded}
            />
          ) : (
            <></>
          )}
        </Box>
        <HStack
          spacing={0}
          zIndex={0}
          bg="rgb(246, 247, 249)"
          maxW="100%"
          h="100%"
          w="100%"
          overflowY={'scroll'}
        >
          <Sidebar
            isMobile={isMobileSize}
            isMobileCollapsed={!isMobileExpanded}
            onMobileCollapse={handleMobileCollapse}
          />{' '}
          <Box
            maxW="100%"
            h="100%"
            px="0"
            py="0"
            zIndex={50}
            w="100%"
            alignSelf={'stretch'}
            pos="relative"
          >
            {children}
            {!isViewingComments && (
              <Box pos="absolute" right="1rem" bottom="1rem">
                <Toolbar />
              </Box>
            )}
            {isMobile && (
              <Box pos="absolute" left="0" bottom="0" w="100%">
                <MobileBanner isAuthed={isAuthed} />
              </Box>
            )}
          </Box>
        </HStack>

        {!isAuthed && !isMobile && <UnauthedBanner />}
      </Flex>
      <Box
        alignItems="stretch"
        id="drawer-root"
        pos={'relative'}
        zIndex={100}
      />
    </>
  )
}

export default PageLayout
