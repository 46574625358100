import React, { ReactElement, useState, useRef } from 'react'
//import { ChevronDownIcon, ChevronUpIcon, HamburgerIcon } from '@chakra-ui/icons'
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'

import {
  Flex,
  HStack,
  Spacer,
  Text,
  Center,
  Portal,
  PopoverContent,
  PopoverBody,
  Popover,
  PopoverTrigger,
  IconButton,
  Circle,
} from '@chakra-ui/react'
import { useHover } from 'react-use'

import { useDashboardNavigationContext } from '../../../../../context/dashboard-navigation/context'
import { dashboardsListItemId } from '../../../../../utils/constants'
import { routes } from '../../../../../utils/constants'
import DefaultAvatar from '../../../../general/DefaultAvatar'
import { MARGIN } from '../../utils'
import { bg, bgActive, bgSelected } from '../../utils'
import { DashboardDraggableData } from './index'
import { BsThreeDots } from 'react-icons/bs'
import DashboardMenu from '../../../../dashboard/dashboardDisplay/header/DashboardMenu'
import { useOutsideListener } from '../../../../../utils/hooks/useOutsideListener'
import { useDashboardSettingsModalContext } from '../../../../../context/dashboard-settings-modal/context'
import { useRouter } from 'next/router'
interface Props {
  badgeNumber?: number
  dashboard: DashboardDraggableData
  index: number
  onCollapseIfMobile: (eventHandler?: () => void) => void
}

const DashboardsListItem = ({
  badgeNumber,
  dashboard,
  index,
  onCollapseIfMobile,
}: Props): ReactElement => {
  const dashboardId = dashboard.id
  const { selectedDashboardId } = useDashboardNavigationContext()
  const router = useRouter()

  function getStyle(style: DraggingStyle | NotDraggingStyle | undefined) {
    if (style?.transform) {
      const axisLockY = `translate(0px, ${style.transform.split(',').pop()}`
      return {
        ...style,
        transform: axisLockY,
      }
    }
    return style
  }

  const { dashboardId: dashboardIdInSettings } =
    useDashboardSettingsModalContext()
  const handleClickOutside = () => {
    setPopoverIsOpen(false)
  }
  const isSelected = dashboardId === selectedDashboardId
  const isDashboardInSettings = dashboardId === dashboardIdInSettings

  const [popoverIsOpen, setPopoverIsOpen] = useState(false)

  const popoverMenuRef = useRef(null)

  useOutsideListener(popoverMenuRef, handleClickOutside)

  const bgStyles = {
    bg: isSelected
      ? bgSelected
      : popoverIsOpen || isDashboardInSettings
      ? 'gray.100'
      : 'auto',

    _hover: {
      textDecoration: 'none',
      bg: isSelected ? 'auto' : bg,
      cursor: 'pointer',
    },
    _active: {
      bg: bgActive,
    },
  }

  const draggableListElement = (isHovering: boolean): ReactElement => {
    return (
      <Flex w="100%">
        <Draggable
          isDragDisabled={false}
          key={dashboardId}
          draggableId={dashboardId}
          index={index}
        >
          {(provided, snapshot) => {
            const shouldUseDraggingStyle = snapshot.isDragging
            return (
              <Flex pos="relative" w="100%">
                <Flex
                  id={dashboardsListItemId(dashboardId)}
                  fontSize="md"
                  direction="column"
                  w="100%"
                  py={'6px'}
                  px={MARGIN}
                  shadow={shouldUseDraggingStyle ? 'md' : 'none'}
                  opacity={shouldUseDraggingStyle ? 0.7 : 1.0}
                  style={getStyle(provided.draggableProps.style)}
                  ref={provided.innerRef}
                  onClick={(e) => {
                    e.preventDefault()
                    onCollapseIfMobile()
                    router.push(routes.dashboard(dashboardId), undefined, {
                      shallow: true,
                    })
                  }}
                  borderRightWidth={'4px'}
                  borderRightStyle={isSelected ? 'solid' : 'none'}
                  borderRightColor="gray.400"
                  {...bgStyles}
                  as="a"
                  href={`${routes.dashboard(dashboardId)}`}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                >
                  <Flex align="center" bg="inherit" direction="row" w="100%">
                    <HStack flexShrink={5} spacing="3">
                      <DefaultAvatar size="xs" user={dashboard.author} />
                      <Text
                        fontWeight={isSelected ? 'medium' : 'auto'}
                        noOfLines={1}
                        textOverflow="clip"
                      >
                        {dashboard.title}
                      </Text>
                    </HStack>
                    <Spacer flexGrow={2} />
                    <HStack pos="absolute" right="3" mx={1} spacing={1}>
                      {(badgeNumber ?? 0) > 0 && (
                        <Circle size="24px" bg="red.500" color="white">
                          <Text fontSize="sm">{badgeNumber}</Text>
                        </Circle>
                      )}
                      <Popover
                        isOpen={popoverIsOpen}
                        onClose={() => setPopoverIsOpen(false)}
                        placement="right"
                      >
                        <PopoverTrigger>
                          <Center
                            //hidden={!isHovered}

                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setPopoverIsOpen(true)
                            }}
                          >
                            <IconButton
                              hidden={!isHovering}
                              size="xs"
                              aria-label="Dashboard menu"
                              borderRadius={'sm'}
                              bg="none"
                              _hover={{
                                bg: isSelected ? 'gray.100' : 'gray.200',
                              }}
                            >
                              <BsThreeDots color="gray.500" />
                            </IconButton>
                          </Center>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent
                            borderRadius="md"
                            w="100%"
                            p={0}
                            ref={popoverMenuRef}
                          >
                            <PopoverBody borderRadius="md" p={0}>
                              <DashboardMenu
                                dashboard={dashboard}
                                isFollowing={true}
                                isAuthor={false} // TODO
                                onClone={() => {
                                  return
                                }}
                                onFollow={async () => {
                                  return
                                }}
                              />{' '}
                            </PopoverBody>
                          </PopoverContent>{' '}
                        </Portal>
                      </Popover>
                    </HStack>
                  </Flex>
                </Flex>
              </Flex>
            )
          }}
        </Draggable>
      </Flex>
    )
  }
  const [hoverableDraggableListElement] = useHover(draggableListElement)

  return <>{hoverableDraggableListElement}</>
}

export default DashboardsListItem
