import NextLink from 'next/link'
import { useRouter } from 'next/router'
/**
 * Profile display for bottom of sidebar
 */
import React, { ReactElement, useCallback } from 'react'

import { Button, HStack, Text, VStack } from '@chakra-ui/react'

import { useCurrentUser } from '../../../../context/user/context'
import DefaultAvatar from '../../../general/DefaultAvatar'

interface Props {
  variant?: 'collapsed' | 'expanded'
  collapseIfMobile: (onClick?: () => void) => void
}

const ProfileDisplay = ({
  variant = 'expanded',
  collapseIfMobile,
}: Props): ReactElement => {
  const router = useRouter()
  const { user } = useCurrentUser()

  const goToProfile = useCallback(() => {
    const username = user?.username
    if (username != undefined) {
      router.push(`/${username}`)
    }
  }, [router, user?.username])

  if (user == undefined) {
    return <></>
  }
  if (variant == 'collapsed') {
    return (
      <DefaultAvatar
        user={{ ...user, id: user.uid ?? '' }}
        onClick={() => collapseIfMobile(goToProfile)}
        size="md"
      />
    )
  }
  return (
    <HStack spacing={3} maxW="100%" isTruncated>
      <DefaultAvatar
        user={{ ...user, id: user.uid ?? '' }}
        size="md"
        onClick={() => collapseIfMobile(goToProfile)}
      />
      <VStack align="start" spacing={0} maxW="100%" isTruncated>
        <Text
          as="b"
          fontSize="md"
          isTruncated
          maxW="100%"
          overflow="hidden"
          noOfLines={1}
        >
          {user.displayName}
        </Text>
        <NextLink href={`/${user.username}`} passHref>
          <Button
            fontSize="sm"
            onClick={() => collapseIfMobile(goToProfile)}
            variant="link"
          >
            View Profile
          </Button>
        </NextLink>
      </VStack>
    </HStack>
  )
}
export default React.memo(ProfileDisplay)
