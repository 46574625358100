import { ReactElement, useState } from 'react'
import { AiOutlineTwitter } from 'react-icons/ai'
import { BiGroup, BiMessageDetail } from 'react-icons/bi'
import { HiOutlineVideoCamera } from 'react-icons/hi'

import {
  Button,
  Divider,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'

import FeedbackForm from '../../forms/FeedbackForm'

const sendSlackMessage = async (
  name: string,
  email: string,
  feedback: string
): Promise<void> => {
  const data = JSON.stringify({
    text: `*\`Display Name\`* - ${name} \n*\`Email\`* - ${email} \n\n*\`Feedback\`* - ${feedback}`,
  })

  const response = await fetch(`/api/feedback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  if (!response.ok) {
    console.error('Error: ', response.status)
  }
}

const HelpPopover = (): ReactElement => {
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false)
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')

  const handleHelpMenuClick = (): void => {
    setIsHelpMenuOpen((prevState) => !prevState)
    setFeedbackText('')
    setIsFeedbackFormOpen(false)
  }

  const handleFeedbackFormSubmit = (
    name: string,
    email: string,
    feedback: string
  ): void => {
    sendSlackMessage(name, email, feedback).then(() => {
      setFeedbackText('')
      handleHelpMenuClick()
      setIsFeedbackFormOpen(false)
    })
  }

  const handleFeedbackChange = (text: string): void => {
    setFeedbackText(text)
  }

  const handleFeedbackFormOpen = (): void => {
    setIsFeedbackFormOpen(true)
  }

  const handleCloseHelpMenu = () => {
    setIsHelpMenuOpen(false)
  }

  return (
    <Popover
      isLazy
      isOpen={isHelpMenuOpen}
      arrowSize={-1}
      trigger="click"
      placement="top-end"
      closeOnBlur={true}
      onClose={handleCloseHelpMenu}
    >
      <PopoverTrigger>
        <Tooltip placement="left" label={'Help menu'} openDelay={1000}>
          <IconButton
            onClick={handleHelpMenuClick}
            size="lg"
            isRound={true}
            icon={<Text fontSize="3xl">?</Text>}
            bg="white"
            aria-label="Help button"
            boxShadow="0 0.75px 2.5px rgb(0 0 0 / 0.5)"
          />
        </Tooltip>
      </PopoverTrigger>
      <PopoverContent
        w="max-content"
        position="fixed"
        right={'5.5rem'}
        bottom={'2rem'}
        borderWidth="0"
        borderRadius="md"
        boxShadow="0 0.75px 2.5px rgb(0 0 0 / 0.5)"
      >
        <PopoverBody
          w="max-content"
          paddingRight={0}
          borderRadius="md"
          paddingLeft={0}
          boxShadow="0 0.75px 2.5px rgb(0 0 0 / 0.5)"
        >
          {isFeedbackFormOpen ? (
            <FeedbackForm
              feedbackText={feedbackText}
              onFeedbackChange={handleFeedbackChange}
              onFeedbackFormSubmit={handleFeedbackFormSubmit}
            />
          ) : (
            <VStack
              w="max-content"
              align="start"
              spacing={0}
              paddingTop={2}
              paddingBottom={2}
            >
              <Button
                borderRadius={0}
                onClick={handleFeedbackFormOpen}
                size="md"
                fontWeight="normal"
                textAlign="start"
                variant="ghost"
                w="100%"
                justifyContent="flex-start"
                leftIcon={<BiMessageDetail />}
              >
                Send us a message
              </Button>

              <Button
                as="a"
                borderRadius={0}
                href="https://calendly.com/signalist/15min"
                size="md"
                fontWeight="normal"
                variant="ghost"
                w="100%"
                target="_blank"
                justifyContent="flex-start"
                leftIcon={<HiOutlineVideoCamera />}
              >
                Schedule a call
              </Button>

              <Button
                as="a"
                borderRadius={0}
                fontWeight="normal"
                href="https://discord.gg/QwkYwNCF"
                size="md"
                variant="ghost"
                w="100%"
                target="_blank"
                justifyContent="flex-start"
                leftIcon={<BiGroup />}
              >
                Join our Discord
              </Button>

              <Divider w="100%" />

              <Button
                size="md"
                as="a"
                borderRadius={0}
                fontSize={'sm'}
                fontWeight="normal"
                variant="ghost"
                w="100%"
                target="_blank"
                href="https://twitter.com/signalistco"
                justifyContent="flex-start"
                leftIcon={
                  <Text mt={'3px'}>
                    <AiOutlineTwitter fontSize={16} />
                  </Text>
                }
                color="gray.500"
              >
                @signalistco{' '}
              </Button>
            </VStack>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default HelpPopover

// Notion's menu styling:
{
  /* <VStack w="max-content" align="start" spacing={1}>
                <Button
                  onClick={handleFeedbackFormOpen}
                  size="sm"
                  align="start"
                  variant="ghost"
                  w="100%"
                  justifyContent="flex-start"
                  leftIcon={<BiMessageDetail />}
                >
                  Send us a message
                </Button>

                <Button
                  as="a"
                  href="https://calendly.com/signalist/15min"
                  size="sm"
                  variant="ghost"
                  w="100%"
                  target="_blank"
                  justifyContent="flex-start"
                  leftIcon={<HiOutlineVideoCamera />}
                >
                  Schedule a call
                </Button>

                <Button
                  as="a"
                  href="https://discord.gg/QwkYwNCF"
                  size="sm"
                  variant="ghost"
                  w="100%"
                  target="_blank"
                  justifyContent="flex-start"
                  leftIcon={<BiGroup />}
                >
                  Join our Discord
                </Button>

                <Divider w="100%" />

                <Button
                  size="sm"
                  fontWeight="400"
                  fontSize="sm"
                  color="gray.500"
                  variant="ghost"
                  w="100%"
                  justifyContent="flex-start"
                >
                  Twitter - @signalist{' '}
                </Button>
              </VStack> */
}
