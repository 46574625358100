/**
 * App bar, rendered on mobile devices (i.e. screens with width up to breakpoint found
 * in the PageLayout component)
 */
import React, { ReactElement } from 'react'
import { Box, Flex, Spacer } from '@chakra-ui/react'
import NavLink from './NavLink'
import { useDashboardNavigationContext } from '../../../context/dashboard-navigation/context'
import { useRouter } from 'next/router'
import { HamburgerIcon } from '@chakra-ui/icons'
import { routes } from '../../../utils/constants'
import { BsSearch, BsPlusSquare } from 'react-icons/bs'
import { useCurrentUser } from '../../../context/user/context'
import { AiOutlineHome, AiFillHome } from 'react-icons/ai'
import { useAuthModalContext } from '../../../context/auth-modal/context'

interface Props {
  onToggleSidebarExpanded: () => void
}

// TODO: use selected and unselected
export const iconSize = 16
export const links = [
  {
    link: routes.home,
    name: 'Home',
    unselectedIcon: <AiOutlineHome size={iconSize} />,
    selectedIcon: <AiFillHome size={iconSize} />,
  },
  {
    link: routes.discover,
    name: 'Discover',
    unselectedIcon: <BsSearch size={iconSize} />,
    selectedIcon: <BsSearch size={iconSize} />,
  },
]

// TODO: use selected and unselected

const MobileAppBar = ({ onToggleSidebarExpanded }: Props): ReactElement => {
  const { createNewDashboard } = useDashboardNavigationContext()
  const { openAuthModal } = useAuthModalContext()
  const { pathname } = useRouter()

  // return (
  //   console.log('rendering') ?? (
  //     <Box pos="sticky" w="100vw" h={10} top={0} bg="gray.100" zIndex={1000} />
  //   )
  // )

  const { user } = useCurrentUser()
  const isAuthed = user != null

  return (
    <Box w="100%" py={2} bg="gray.100" verticalAlign="center">
      <Flex w="100%" h="100%">
        <Spacer />
        <NavLink onClick={onToggleSidebarExpanded}>
          <HamburgerIcon fontSize={iconSize} />
        </NavLink>
        <Spacer />
        {links.map((l) => (
          <>
            <NavLink
              key={l.link}
              href={l.link}
              isSelected={pathname.indexOf(l.link) === 0}
            >
              {l.unselectedIcon}
            </NavLink>
            <Spacer />
          </>
        ))}
        <NavLink
          onClick={
            isAuthed ? createNewDashboard : () => openAuthModal('signUp')
          }
        >
          <BsPlusSquare size={iconSize - 2} />
        </NavLink>
        <Spacer />
      </Flex>
    </Box>
  )
}
export default React.memo(MobileAppBar)
